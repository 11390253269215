import "core-js/modules/es.array.push.js";
export default {
  props: ['houseItem'],
  data() {
    return {
      information_id: ''
    };
  },
  methods: {
    toDetail() {
      console.log(111111);
      // if(!this.$store.state.userinfo){
      //    this.$message.error(this.$t('other.noLoginPleaseToLogin'));
      //     setTimeout(() => {
      // 		this.$router.push({
      // 			path: "/accountLogin"
      // 		});
      // 	}, 1500)  
      //    return false;
      // }
      this.$router.push({
        path: "/detail",
        // path: '/detail',
        query: {
          information_id: this.houseItem.id
        }
      });

      // const path=this.$router.resolve({
      // 	path: '/detail',

      // 	query: {
      // 		information_id: this.houseItem.id,
      // 	}
      // })

      // console.log(path)
      // window.open(path.href,'_blank')
    }
  }
};