var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "house_item_all"
  }, [_c('div', {
    staticClass: "house_item",
    on: {
      "click": _vm.toDetail
    }
  }, [_vm.houseItem.is_hot == 2 ? _c('div', {
    staticClass: "collect"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/icons/important.png")
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "house_img"
  }, [_c('img', {
    attrs: {
      "src": _vm.houseItem.thumb[0]
    }
  })]), _c('div', {
    staticClass: "house_title"
  }, [_vm._v(" " + _vm._s(_vm.houseItem.title) + " ")]), _c('div', {
    staticClass: "house_price"
  }, [_vm.houseItem.price != '0.00' ? _c('div', {
    staticClass: "house_price_left"
  }, [_vm._v(" $" + _vm._s(_vm.houseItem.price) + " ")]) : _vm._e(), _vm.houseItem.company_logo_url ? _c('div', {
    staticClass: "house_price_right"
  }, [_c('img', {
    attrs: {
      "src": _vm.houseItem.company_logo_url
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "cate"
  }, [_vm._v(" " + _vm._s(_vm.houseItem.cate_name) + " ")]), _c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "address"
  }, [_vm._v(_vm._s(_vm.houseItem.province_name))]), _c('div', {
    staticClass: "address"
  }, [_vm._v(_vm._s(_vm.houseItem.create_time))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };